import 'classlist-polyfill';
import Promise from 'bluebird';
import Markdown from 'markdown';
const md = Markdown.markdown.toHTML;
import workText from 'raw-loader!./work.md';
import footerHTML from 'raw-loader!./footer.html';
let styleText = [0, 1, 2, 3].map((i) => require('raw-loader!./styles' + i + '.css').default);
import preStyles from 'raw-loader!./prestyles.css';
import replaceURLs from './lib/replaceURLs';
import { default as writeChar, writeSimpleChar, handleChar } from './lib/writeChar';
import getPrefix from './lib/getPrefix';

// Vars that will help us get er done
const isDev = window.location.hostname === 'localhost';
const speed = isDev ? 0 : 16;
let style, styleEl, workEl, skipAnimationEl, pauseEl;
let animationSkipped = false, done = false, paused = false;
let browserPrefix;

// Wait for load to get started.
document.addEventListener("DOMContentLoaded", function () {
  getBrowserPrefix();
  populateHeader();
  getEls();
  createEventHandlers();
  printInConsole();
  startAnimation();
});

async function startAnimation() {
  try {
    await writeTo(styleEl, styleText[0], 0, speed, true, 1);
    await writeTo(workEl, workText, 0, 8, false, 2);
    await writeTo(styleEl, styleText[1], 0, speed, true, 1);
    createWorkBox();
    await Promise.delay(1000);
    await writeTo(styleEl, styleText[2], 0, speed, true, 1);
    await writeTo(styleEl, styleText[3], 0, speed, true, 1);
  }
  // Flow control straight from the ghettos of Milwaukee
  catch (e) {
    if (e.message === "SKIP IT") {
      surprisinglyShortAttentionSpan();
    } else {
      throw e;
    }
  }
}

// Skips all the animations.
async function surprisinglyShortAttentionSpan() {
  if (done) return;
  done = true;
  let txt = styleText.join('\n');

  // The work-text animations are rough
  style.textContent = "#work-text * { " + browserPrefix + "transition: none; }";
  style.textContent += txt;
  let styleHTML = "";
  for (let i = 0; i < txt.length; i++) {
    styleHTML = handleChar(styleHTML, txt[i]);
  }
  styleEl.innerHTML = styleHTML;
  createWorkBox();

  // There's a bit of a scroll problem with this thing
  let start = Date.now();
  while (Date.now() - 1000 > start) {
    workEl.scrollTop = Infinity;
    await Promise.delay(16);
  }
}


/**
 * Helpers
 */

let endOfSentence = /[\.\?\!]\s$/;
let comma = /\D[\,]\s$/;
let endOfBlock = /[^\/]\n\n$/;

async function writeTo(el, message, index, interval, mirrorToStyle, charsPerInterval) {
  if (animationSkipped) {
    // Lol who needs proper flow control
    throw new Error('SKIP IT');
  }
  // Write a character or multiple characters to the buffer.
  let chars = message.slice(index, index + charsPerInterval);
  index += charsPerInterval;

  // Ensure we stay scrolled to the bottom.
  el.scrollTop = el.scrollHeight;

  // If this is going to <style> it's more complex; otherwise, just write.
  if (mirrorToStyle) {
    writeChar(el, chars, style);
  } else {
    writeSimpleChar(el, chars);
  }

  // Schedule another write.
  if (index < message.length) {
    let thisInterval = interval;
    let thisSlice = message.slice(index - 2, index + 1);
    if (comma.test(thisSlice)) thisInterval = interval * 30;
    if (endOfBlock.test(thisSlice)) thisInterval = interval * 50;
    if (endOfSentence.test(thisSlice)) thisInterval = interval * 70;

    do {
      await Promise.delay(thisInterval);
    } while (paused);

    return writeTo(el, message, index, interval, mirrorToStyle, charsPerInterval);
  }
}

//
// Older versions of major browsers (like Android) still use prefixes. So we figure out what that prefix is
// and use it.
//
function getBrowserPrefix() {
  // Ghetto per-browser prefixing
  browserPrefix = getPrefix(); // could be empty string, which is fine
  styleText = styleText.map(function (text) {
    return text.replace(/-webkit-/g, browserPrefix);
  });
}

//
// Put els into the module scope.
//
function getEls() {
  // We're cheating a bit on styles.
  let preStyleEl = document.createElement('style');
  preStyleEl.textContent = preStyles;
  document.head.insertBefore(preStyleEl, document.getElementsByTagName('style')[0]);

  // El refs
  style = document.getElementById('style-tag');
  styleEl = document.getElementById('style-text');
  workEl = document.getElementById('work-text');
  skipAnimationEl = document.getElementById('skip-animation');
  pauseEl = document.getElementById('pause-resume');
}

//
// Create links in footers.
//
function populateHeader() {
  let footer = document.getElementById('footer');
  footer.innerHTML = footerHTML;
}

//
// Create basic event handlers for user input.
//
function createEventHandlers() {
  // Mirror user edits back to the style element.
  styleEl.addEventListener('input', function () {
    style.textContent = styleEl.textContent;
  });

  // Skip anim on click to skipAnimation
  skipAnimationEl.addEventListener('click', function (e) {
    e.preventDefault();
    animationSkipped = true;
  });

  pauseEl.addEventListener('click', function (e) {
    e.preventDefault();
    if (paused) {
      pauseEl.innerHTML = `<span class="fa-stack">
      <i class="fas fa-square fa-stack-2x" style="color:white"></i>
      <i class="fas fa-pause fa-stack-1x" style="color:orange"></i>
      </span>`;
      pauseEl.title = 'Pause';
      paused = false;
    } else {
      pauseEl.innerHTML = `<span class="fa-stack">
      <i class="fas fa-square fa-stack-2x" style="color:white"></i>
      <i class="fas fa-play fa-stack-1x" style="color:green"></i>
      </span>`;
      pauseEl.title = 'Resume';
      paused = true;
    }
  });
}

//
// Fire a listener when scrolling the 'work' box.
//
function createWorkBox() {
  if (workEl.classList.contains('flipped')) return;
  workEl.innerHTML = '<div class="md">' + replaceURLs(md(workText)) + '<div>';

  workEl.classList.add('flipped');
  workEl.scrollTop = 9999;

  require('mouse-wheel')(workEl, async function (dx, dy) {
    // Scroll. If we've flipped, flip the scroll direction.
    workEl.scrollTop += (dy * -1);
  }, true);
}

function printInConsole() {
  console.log('######################################################');
  console.log('# Si vous me contacter je serais très content de     #');
  console.log('# vous partagez les sources ou de discuter de mon cv #');
  console.log('# avec vous. Vous pouvez me contacter via LinkedIN   #');
  console.log('# ou par mail a frederic at hocansung dot com          #');
  console.log('#                                             Merci. #');
  console.log('######################################################');
}